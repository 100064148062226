import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import {
  getMMMInfluence,
  getMMMModelStackedPlot,
  getMMMStatistics,
} from '../services/model'
import { useAuth } from '../providers/AuthProvider'
import { useMemo } from 'react'

export default function useBaseline({ model }) {
  const { token } = useAuth()

  const { data: stackedPlot } = useQuery(
    ['mediaContribution', model.id],
    async () => {
      const response = await getMMMModelStackedPlot({
        modelId: model.id,
        token,
      })
      if (response?.ok) return await response.json()
    },
    { staleTime: Infinity },
  )

  const mmmstats = useQuery(
    ['mmm-model-statistics', model.id],
    async () => {
      const response = await getMMMStatistics({
        modelId: model.id,
        token,
      })
      if (!response?.ok) toast.error(t('Failed to retrieve original forecast'))

      return await response.json()
    },
    { staleTime: Infinity },
  )

  const { data, isSuccess } = useQuery(
    ['MMMInfluence', model.id],
    async () => {
      const response = await getMMMInfluence({ modelId: model.id, token })
      if (response.ok) return await response.json()
    },
    { staleTime: Infinity },
  )

  const contributionExtraFeatures = useMemo(() => {
    return data?.data?.map((v) => v.reduce((a, b) => a + b, 0))
  }, [data])

  const percBaseline = useMemo(() => {
    const index = stackedPlot?.columns?.indexOf('baseline')
    return stackedPlot?.data?.map(
      (v, i) => v[index] - (contributionExtraFeatures?.[i] ?? 0),
    )
  }, [contributionExtraFeatures, stackedPlot])

  const realBaseline = useMemo(() => {
    return mmmstats?.data?.y?.map((v, i) => v * percBaseline?.[i])
  }, [percBaseline, mmmstats?.data])

  return {
    percBaseline,
    realBaseline,
    contributionExtraFeatures,
    percTotalBaselineContribution:
      realBaseline?.reduce((a, b) => a + b, 0) /
        mmmstats?.data?.y?.reduce((a, b) => a + b, 0) || 0,
  }
}
