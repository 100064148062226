import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from './assets/locales/en/translation.json'
import translationES from './assets/locales/es/translation.json'
import translationDE from './assets/locales/de/translation.json'
import translationFR from './assets/locales/fr/translation.json'
import translationPT from './assets/locales/pt/translation.json'
import translationIT from './assets/locales/it/translation.json'

const fallbackLng = ['en']
const availableLanguages = ['en', 'es', 'de', 'fr', 'pt', 'it']

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
  pt: {
    translation: translationPT,
  },
  it: {
    translation: translationIT,
  },
}

i18n
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng,
    //lng: 'en', // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    whitelist: availableLanguages,
  })

export default i18n
