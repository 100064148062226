import { useMemo, useEffect } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { useQuery, useQueryClient } from 'react-query'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { generateMMMColorMap } from '../../utility/model'
import { useTranslation } from 'react-i18next'

import { nivoProps } from '../../utility/model'
import { useAuth } from '../../providers/AuthProvider'
import Loader from '../Loader'
import {
  getMMMInfluence,
  getMMMOptimizedTable,
  getMMMStatistics,
} from '../../services/model'
import { round } from '../../utility/format'
import { FaThumbsUp } from 'react-icons/fa'
import { MdOutlineWarning } from 'react-icons/md'
import useBaseline from '../../hooks/useBaseline'

export default function SpendContribution({ model, height = 250, ...props }) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { token } = useAuth()

  const { percTotalBaselineContribution } = useBaseline({ model })

  const { data } = useQuery(
    ['mmm-optimized-table-spend', model.id],
    async () => {
      let data = null
      try {
        const response = await getMMMOptimizedTable({
          modelId: model.id,
          is_outcome_optimization: false,
          weekly: false,
          original: true,
          token,
        })
        if (response.ok) data = await response.json()
      } catch (e) {
        console.info('Error retrieving mmm optimized table spend')
      }
      return data
    },
    { staleTime: Infinity },
  )
  const { data: statistics } = useQuery(
    ['mmm-model-statistics', model.id],
    async () => {
      const response = await getMMMStatistics({
        modelId: model.id,
        token,
      })

      if (!response?.ok) toast.error(t('Failed to retrieve original forecast'))
      else return await response.json()
    },
    { staleTime: 60 * 1000 },
  )

  const total = useMemo(
    () => statistics?.y?.reduce((a, b) => a + b, 0) ?? 0,
    [statistics],
  )

  const { data: influence, isSuccess } = useQuery(
    ['MMMInfluence', model.id],
    async () => {
      const response = await getMMMInfluence({ modelId: model.id, token })
      if (response.ok) return await response.json()
    },
    { staleTime: Infinity },
  )
  const nonMediaContribution = useMemo(() => {
    return (
      influence?.columns?.reduce((a, series, i) => {
        a[series] = influence.data.reduce((a, b) => a + b[i], 0)
        return a
      }, {}) ?? {}
    )
  }, [influence])
  console.log(influence)

  useEffect(() => {
    if (!data && model) {
      const to = setInterval(() => {
        queryClient.invalidateQueries(['mmm-optimized-table-spend', model.id])
      }, 3000)
      return () => clearInterval(to)
    }
  }, [data])

  const colorMap = useMemo(() => generateMMMColorMap(model), [model])
  const mixEffects = useMemo(() => {
    if (!data) return []
    try {
      const keymap = data.columns.reduce((acc, k, i) => {
        acc[k] = i
        return acc
      }, {})
      const total = data.data
        .map((r) => r[keymap['Media spend']])
        .reduce((a, b) => a + b, 0)
      const items = data.data.map((r) => ({
        media: r[keymap['index']],
        'Share of Effect': r[keymap['Media contribution']],
        'Share of Spend': (100 * r[keymap['Media spend']]) / total,
        spent: r[keymap['Media spend']],
        color: colorMap?.[r[0]],
      }))
      const otherItems = []
      Object.entries(nonMediaContribution).forEach(([k, v]) => {
        otherItems.push({
          media: k,
          'Share of Effect': v,
          'Share of Spend': 0,
          spent: 0,
          color: 'white',
          negative: v < 0,
        })
      })
      otherItems.push({
        media: t('Baseline'),
        'Share of Effect': percTotalBaselineContribution,
        'Share of Spend': 0,
        spent: 0,
        color: 'red',
        negative: percTotalBaselineContribution < 0,
      })
      items.sort((a, b) => a['Share of Effect'] - b['Share of Effect'])
      otherItems.sort((a, b) => a['Share of Effect'] - b['Share of Effect'])
      otherItems.forEach(
        (i) => (i['Share of Effect'] = Math.abs(i['Share of Effect'])),
      )
      otherItems.push(...items)
      return otherItems
    } catch (e) {
      return []
    }
  }, [data, nonMediaContribution])

  const itemNames = useMemo(() => {
    const keymap = data?.columns?.reduce((acc, k, i) => {
      acc[k] = i
      return acc
    }, {})
    return data?.data?.map((r) => r[keymap['index']]) ?? []
  }, [data])

  if (!data) return <Loader />

  const baseline = mixEffects?.find((r) => r.media === 'Baseline')?.[
    'Share of Effect'
  ]

  return (
    <Row
      {...props}
      className={`media-contribution-bars ${props.className ?? ''} data-holder`}
      data-csv={encodeURIComponent(
        JSON.stringify([
          ['channel', 'Total spent', 'Share of Effect', 'Share of Spend'],
          ...mixEffects.map((d) => [
            d['media'],
            d['spent'],
            d['Share of Effect'],
            d['Share of Spend'],
          ]),
        ]),
      )}
      data-filename={`media_effects_average__${model.id}`}
    >
      <Col className="text-sm" xs={12}>
        {t(
          '{{baseline}}% of the effect is driven by media channels and context variables, while {{nmc}}% is attributed to the baseline, which is non-media contributions that the model cannot pinpoint to a specific source.',
          { baseline: Math.floor(100 - baseline), nmc: Math.floor(baseline) },
        )}
      </Col>
      <Col style={{ minHeight: `${mixEffects?.length * 100}px` }} xs={12}>
        <ResponsiveBar
          {...nivoProps}
          data={mixEffects}
          keys={['Share of Spend', 'Share of Effect']}
          valueFormat={(n) => `${round(n, 2)}%`}
          groupMode="grouped"
          layout="horizontal"
          enableSlices="y"
          borderRadius={2}
          colors={(d) => (d.id === 'Share of Effect' ? '#EBD0FF' : '#9604FF')}
          indexBy="media"
          xScale={{ type: 'linear' }}
          enableGridY={false}
          enableGridX={false}
          margin={{ top: 40, right: 80, bottom: 60, left: 200 }}
          padding={0.3}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          label={(_) => <></>}
          axisBottom={null}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -60,
            legendPosition: 'middle',
            renderTick: ({ x, y, value }) => {
              const item = mixEffects.find((i) => i.media === value)
              return (
                <g transform={`translate(${x - 200},${y - 50})`}>
                  <foreignObject x={0} y={0} width={200} height={100}>
                    <Row className="min-w-[200px] max-w-[200px] min-h-[100px] max-h-[100px] items-center">
                      <Col
                        className="flex items-center px-0  max-h-[80px] text-[12px] justify-end text-center"
                        xs={12}
                      >
                        {itemNames?.includes(value) && (
                          <>
                            {item?.['Share of Effect'] <
                            item?.['Share of Spend'] ? (
                              <OverlayTrigger
                                rootClose={true}
                                trigger={['hover', 'focus']}
                                placement="top"
                                delay={{ show: 100, hide: 100 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    <Row className="p-2 text-[13px]">
                                      <Col xs={12}>
                                        {t(
                                          'You’ve probably overspent on this channel; the effect on your KPI doesn’t seem as profitable as the investment.',
                                        )}
                                      </Col>
                                    </Row>
                                  </Tooltip>
                                )}
                              >
                                <span className="me-2">
                                  <MdOutlineWarning
                                    size={24}
                                    className="scale-x-[-1]"
                                    color="#df4e4e"
                                  />
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                rootClose={true}
                                trigger={['hover', 'focus']}
                                placement="top"
                                delay={{ show: 100, hide: 100 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    <Row className="p-2 text-[13px]">
                                      <Col className="font-bold" xs={12}>
                                        {t(' Looks promising!')}
                                      </Col>
                                      <Col xs={12}>
                                        {t(
                                          'Your investment in this channel seems to be paying off.',
                                        )}
                                      </Col>
                                    </Row>
                                  </Tooltip>
                                )}
                              >
                                <span className="me-2 p-[8px] rounded-full bg-green-500">
                                  <FaThumbsUp size={12} />
                                </span>
                              </OverlayTrigger>
                            )}
                          </>
                        )}
                        <span className="line-clamp-3 max-w-[70%] w-fit">
                          {value}
                        </span>
                      </Col>
                    </Row>
                  </foreignObject>
                </g>
              )
            },
          }}
          defs={[]}
          fill={[]}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="black"
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 50,
              itemsSpacing: 2,
              itemWidth: 150,
              itemHeight: 20,
              symbolShape: 'circle',
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 15,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          layers={[
            'grid',
            'axes',
            'bars',
            'markers',
            'legends',
            'annotations',
            ({ bars }) => {
              return (
                <g>
                  {bars.map((props, index) => {
                    const { width, x, y, data } = props
                    if (data.value < 0.1) return null
                    const isNegative = data?.data?.negative

                    return (
                      <text
                        key={`${data.value}_${index}`}
                        transform={`translate(${x + width + 10}, ${y + 15})`}
                        fontSize={12}
                        textAnchor="left"
                        dominantBaseline="central"
                        fontWeight="bold"
                        fill={isNegative ? '#ee4354' : 'var(--mmm-white-color)'}
                      >
                        {`${data?.data?.negative ? '-' : ''}${data.formattedValue ?? ''}`}
                      </text>
                    )
                  })}
                </g>
              )
            },
          ]}
        />
      </Col>
    </Row>
  )
}
