import { useEffect, useState } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import ViewDataResume from './ViewDataResume'
import { MdWarning } from 'react-icons/md'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSelect from './CustomSelect'
import ButtonMMM from './ButtonMMM'
import { useQueryClient } from 'react-query'
import { updateColumnDateFormat } from '../services/model'
import { useAuth } from '../providers/AuthProvider'
import { FaEdit } from 'react-icons/fa'
import useStoreState from '../hooks/UseStoreState'
import { getModelStatus } from '../utility/model'

const typeMap = {
  Datetime: 'Datetime',
  Integer: 'Numeric',
  float64: 'Numeric',
  object: 'Category',
}

const colorMap = {
  Datetime: 'border-2 border-solid border-pink-600',
  Integer: 'border-2 border-solid border-green-600',
  float64: 'border-2 border-solid border-green-600',
  object: 'border-2 border-solid border-orange-600',
}

function ChangeTypeModal({ show, onHide, model, column, onSave }) {
  const { token } = useAuth()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [selectedFormat, setSelectedFormat] = useState(() => {
    if (model?.datetime_formats?.[column])
      return {
        label: model?.datetime_formats?.[column],
        value: model?.datetime_formats?.[column],
      }
  })

  return (
    <Modal onHide={onHide} show={show}>
      <Modal.Header closeButton>{t('Change types')}</Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <CustomSelect
              className="basic-single mt-2"
              classNamePrefix="select"
              isSearchable={true}
              placeholder={t('Select a date format')}
              isClearable={true}
              onChange={(e) => {
                setSelectedFormat(e)
              }}
              options={
                model.datetime_options?.[column]?.map((l) => ({
                  label: l,
                  value: l,
                })) ?? []
              }
              value={selectedFormat}
              isDisabled={getModelStatus(model) === 'training'}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-full">
          <Col xs={12}>
            <ButtonMMM
              disabled={!selectedFormat}
              onClick={() => {
                onHide()
                updateColumnDateFormat({
                  modelId: model?.id,
                  column,
                  format: selectedFormat.value,
                  token,
                }).then((r) => {
                  if (r.ok) {
                    onSave()
                    queryClient.invalidateQueries(['model', model?.id])
                  }
                })
              }}
            >
              {t('Save')}
            </ButtonMMM>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default function ColumnAndType({
  column,
  summary,
  width,
  header,
  setRows,
  model,
}) {
  const [showChangeDate, setShowChangeDate] = useState(false)
  const [changedDate, setChangedDate, forceRewrite] = useStoreState({
    key: 'changedDf',
  })
  const { t } = useTranslation()
  const type = model?.column_types?.[column]
  const dateOptions = model.datetime_options?.[column]
  const multipleDateOptions =
    Array.isArray(dateOptions) && dateOptions.length > 1
  const resume = useMemo(
    () => (
      <ViewDataResume
        summary={summary}
        width={width}
        model={model}
        column={column}
      />
    ),
    // eslint-disable-next-line
    [summary, width],
  )
  const handleConfigChange = (modelId, isConfigured) => {
    let dateFormatConfigurations =
      JSON.parse(localStorage.getItem('changedDf')) || {}
    dateFormatConfigurations[modelId] = isConfigured
    setChangedDate(dateFormatConfigurations)
  }

  return (
    <Row
      className="flex-column column-type-control justify-content-between p-2 pb-0"
      title={column}
    >
      <Col
        className={`d-flex flex-row flex-nowrap justify justify-content-between`}
        xs={'auto'}
        onClick={() => {
          const index = header[0].findIndex((e) => e === column)
          if (sort)
            setRows((r) => [
              ...r.sort((a, b) => (a[index] < b[index] ? 1 : -1)),
            ])
          else
            setRows((r) => [
              ...r.sort((a, b) => (a[index] > b[index] ? 1 : -1)),
            ])
          setSort((s) => !s)
        }}
      >
        <div className={`inline-block text-truncate header-line flex-nowrap`}>
          <span>{column}</span>
        </div>
      </Col>
      <Col
        xs={'auto'}
        className={`d-flex flex-row flex-nowrap justify justify-start items-center`}
      >
        <div
          className={`inline-block text-truncate header-line rounded-sm color-white font-extrabold px-3 py-1 ${colorMap[type]}`}
        >
          {t(typeMap[type] ?? type)}
        </div>
        {multipleDateOptions && (
          <OverlayTrigger
            rootClose={true}
            trigger={['hover', 'focus']}
            placement="auto"
            delay={{ show: 100, hide: 100 }}
            overlay={(props) => (
              <Tooltip {...props}>
                {t(
                  'The column contains multiple date formats. Click to customize.',
                )}
              </Tooltip>
            )}
          >
            <span
              className="cursor-pointer"
              onClick={() => setShowChangeDate(true)}
            >
              {changedDate[model?.id] !== true ? (
                <MdWarning className="ms-2" size={25} />
              ) : (
                <FaEdit className="ms-2" size={20} />
              )}
            </span>
          </OverlayTrigger>
        )}
        {showChangeDate && (
          <ChangeTypeModal
            model={model}
            column={column}
            show={showChangeDate}
            onHide={() => setShowChangeDate(false)}
            onSave={() => handleConfigChange(model?.id, true)}
          />
        )}
      </Col>
      <Col className="px-0" xs={12}>
        {resume}
      </Col>
    </Row>
  )
}
