import { Row, Col, Popover, OverlayTrigger } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import backImg from '../assets/logos/back.png'
import { Link } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'
import proCrown from '../assets/images/pro_crown.svg'
import { getModelStatus } from '../utility/model'

const STEPS = [
  { name: 'My data' },
  { name: 'Insights' },
  { name: 'Optimization' },
  { name: 'Planning' },
]

function BreadcrumbStep({ step, active, disabled, isPro, ...props }) {
  const { t } = useTranslation()
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {t('Model must be trained to access this step')}
      </Popover.Body>
    </Popover>
  )
  const breadcrumbContent = (
    <Col
      {...props}
      className={`breadcrumbs-step select-none inline-flex flex-nowrap px-0 ${
        props?.className ?? ''
      } ${active ? 'active-breadcrumb' : ''} ${disabled ? 'disabled-breadcrumb' : 'cursor-pointer'}`}
      xs="auto"
    >
      <span className="px-4 inline-flex items-center py-2 me-2 text-lg relative font-medium">
        {isPro ? (
          <img src={proCrown} className={`me-3 ms-1 w-[18px]`} />
        ) : (
          <FaCheckCircle className={`me-3 ms-1 ${active ? '' : 'opacity-0'}`} />
        )}
        {t(`${step.name}`)}
      </span>
    </Col>
  )
  return disabled ? (
    <OverlayTrigger
      rootClose={true}
      trigger={['hover', 'focus']}
      placement="auto"
      delay={{ show: 100, hide: 100 }}
      overlay={popover}
    >
      {breadcrumbContent}
    </OverlayTrigger>
  ) : (
    breadcrumbContent
  )
}

export default function BreadcrubsModel({
  activeStep = 2,
  onChange,
  model,
  ...props
}) {
  const navigate = useNavigate()
  const { isEssential } = useAuth()
  const { t } = useTranslation()
  return (
    <Row className="items-center">
      <Col xs="auto" className="me-12 absolute">
        {/* <ButtonMMM
          className="py-1 pe-2 button-mmm-info"
          onClick={() => navigate('/')}
        >
          <div className="inline-flex flex-nowrap items-center me-3 ms-1 align-middle">
            <FaArrowLeft className="me-2" />
            {t('Back')}
          </div>
        </ButtonMMM> */}
        <Link to="/">
          <img className="icon-item h-10 max-w-none" src={backImg} />
        </Link>
      </Col>
      <Col className="flex justify-center">
        {STEPS.map((step, index) => {
          const status = getModelStatus(model)
          const disabled =
            model &&
            status !== 'trained' &&
            status !== 'optimizing' &&
            (index === 2 || index === 3)
          return (
            <BreadcrumbStep
              onClick={() => !disabled && onChange(index)}
              className={`breadcrumb-${index}`}
              active={index <= activeStep}
              disabled={disabled}
              key={index}
              step={step}
              isPro={index > 1 && isEssential}
            />
          )
        })}
      </Col>
    </Row>
  )
}
