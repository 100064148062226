export const categories = Object.freeze({
  accent: [
    '#7fc97f',
    '#beaed4',
    '#fdc086',
    '#ffff99',
    '#386cb0',
    '#f0027f',
    '#bf5b17',
    '#1199dd',
    '#EC812F',
  ],
  dark: [
    '#1b9e77',
    '#d95f02',
    '#7570b3',
    '#e7298a',
    '#66a61e',
    '#e6ab02',
    '#a6761d',
    '#1199dd',
  ],
  greenScale: [
    '#cef1dd',
    '#9ee3bb',
    '#6dd499',
    '#3cc677',
    '#33a865',
    '#2a8b53',
    '#216d41',
    '#077026',
  ],
  highContrast: [
    '#b8ebf0',
    '#ded0eb',
    '#f2bdbe',
    '#f2bdbe',
    '#f2bdbe',
    '#ffffff',
    '#b8ebf0',
    '#ded0eb',
    '#f2bdbe',
    '#f2bdbe',
    '#f2bdbe',
    '#ffffff',
  ],
  mmm: [
    'rgb(27, 158, 119)',
    'rgb(217, 95, 2)',
    'rgb(117, 112, 179)',
    'rgb(231, 41, 138)',
    'rgb(102, 166, 30)',
    'rgb(230, 171, 2)',
    'rgb(166, 118, 29)',
    'rgb(102, 102, 102)',
    'rgb(152, 192, 102)',
    'rgb(55, 180, 76)',
    'rgb(175, 37, 37)',
    'rgb(71, 3, 71)',
    'rgb(100, 37, 0)',
    'rgb(153, 174, 201)',
    'rgb(131, 201, 20)',
    'rgb(58, 50, 105)',
    'rgb(156, 85, 109)',
    'rgb(24, 66, 7)',
    'rgb(185, 236, 0)',
    'rgb(0, 69, 82)',
    'rgb(226, 198, 145)',
  ],
  color10: [
    'rgb(31, 119, 180)',
    'rgb(255, 127, 14)',
    'rgb(44, 160, 44)',
    'rgb(214, 39, 40)',
    'rgb(148, 103, 189)',
    'rgb(140, 86, 75)',
    'rgb(227, 119, 194)',
    'rgb(127, 127, 127)',
    'rgb(188, 189, 34)',
    'rgb(23, 190, 207)',
  ],
  spectral: [
    'rgb(158, 1, 66)',
    'rgb(213, 62, 79)',
    'rgb(244, 109, 67)',
    'rgb(253, 174, 97)',
    'rgb(254, 224, 139)',
    'rgb(255, 255, 191)',
    'rgb(230, 245, 152)',
    'rgb(171, 221, 164)',
    'rgb(102, 194, 165)',
    'rgb(50, 136, 189)',
    'rgb(94, 79, 162)',
  ],
})

export const dataTypeCategories = Object.freeze({
  integer: categories.accent[0], //Change these in App.css variables for consistency
  key: categories.accent[1],
  float: categories.accent[2],
  categorical: categories.accent[8],
  date: categories.accent[5],
  unknown: categories.accent[7],
})

export const categoryToColorDataTypes = Object.freeze({
  Categorical: dataTypeCategories.categorical,
  Decimal: dataTypeCategories.float,
  Integer: dataTypeCategories.integer,
  Datetime: dataTypeCategories.date,
})

export function generateMMMColorMap(model) {
  const colors = categories['mmm']
  const target = model?.training_config?.target
  const date = model?.training_config?.datetime_col
  const extraFeatures = new Set(model?.training_config?.extra_features ?? [])

  const res = (model?.trainable_columns ?? [])
    .filter((c) => c !== target && c !== date && !extraFeatures.has(c))
    .sort()
    .concat([...extraFeatures].sort())
    .reduce((acc, k, i) => {
      acc[k] = colors[i % colors.length]
      return acc
    }, {})

  res['baseline'] = '#3ec73e'
  res['Baseline'] = '#3ec73e'
  res['Sum of Non Media Contribution'] = '#3ec73e'
  return res
}

export const mmmTheme = {
  fontSize: 11,
  textColor: 'white',
  axis: {
    ticks: {
      text: {
        fontSize: 11,
      },
    },
    legend: {
      text: {
        fontSize: 13,
        fill: 'white',
      },
    },
    domain: {
      line: {
        stroke: 'var(--graph-grid-color)',
        strokeWidth: 1,
      },
    },
  },
  tooltip: {
    container: {
      color: 'black',
    },
  },
  legends: {
    text: {
      fontSize: 11,
    },
  },
  grid: {
    line: {
      stroke: 'var(--graph-grid-color)',
      opacity: 0.4,
    },
  },
}

export const nivoProps = {
  margin: { top: 10, right: 10, bottom: 10, left: 10 },
  xFormat: ' >-.2f',
  yFormat: ' >-.2f',
  enableGridX: true,
  enableGridY: true,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    legendPosition: 'middle',
    tickRotation: -22,
  },
  axisLeft: {
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
  },
  useMesh: false,
  theme: mmmTheme,
}

export function getTextWidth(text, font, useCanvas = true) {
  if (useCanvas) {
    var a = document.createElement('canvas')
    var b = a.getContext('2d')
    //b.font = fontSize + 'px ' + fontFace
    b.font = font
    return b.measureText(text).width
  } else {
    const el = document.createElement('span')
    el.textContent = text
    el.style.font = font
    el.style.position = 'absolute'
    el.style.left = -1000
    el.style.top = -1000
    document.body.appendChild(el)
    //var s = getComputedStyle(el)
    const width = el.clientWidth
    document.body.removeChild(el)
    return width
  }
}

export const baseFormat = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export function getMMMDataColumnInfo(model, prefix = '') {
  const col = model?.training_config?.datetime_col
  const mode = model?.column_types?.[col]
  if (mode === 'Datetime') {
    const config = model?.column_statistics?.[col]
    const date = new Date(`${config?.min}Z`)
    if (!Number.isNaN(date.getTime()))
      return {
        mode: 'datetime',
        min: date,
        map: function (i, format = baseFormat, isMonthly = false) {
          if (!format) format = baseFormat
          let date = new Date(this.min.getTime() + i * 60 * 60 * 24 * 7 * 1000)
          if (isMonthly) {
            date = new Date(this.min.getFullYear(), this.min.getMonth() + i, 1)
          }
          return format ? format(date) : date
        },
        _mapMonthCache: {},
        mapMonth: function (i) {
          if (this._mapMonthCache[i]) return this._mapMonthCache[i]
          const mapping = {}
          const start = new Date(
            this.min.getTime() + i * 60 * 60 * 24 * 7 * 1000,
          )
          const end = new Date(
            this.min.getTime() + (i + 1) * 60 * 60 * 24 * 7 * 1000,
          )
          const startMonth = start.getMonth()
          const endMonth = end.getMonth()
          const baseMonth =
            start.getMonth() -
            this.min.getMonth() +
            12 * (start.getFullYear() - this.min.getFullYear())
          if (startMonth === endMonth) mapping[baseMonth] = 1
          else {
            const days = end.getDay()
            mapping[baseMonth] = 1 - days / 7
            mapping[baseMonth + 1] = days / 7
          }
          this._mapMonthCache[i] = mapping
          return mapping
        },
      }
  }
  return {
    mode: 'integer',
    min: 1,
    map: (i) => prefix + (i + 1),
    mapMonth: (i) => {
      return { [i % 4]: 1 }
    },
  }
}

export const colors = categories.mmm

export const integerParams = Object.freeze({
  xScale: { type: 'linear', min: 'auto', max: 'auto' },
  axisBottom: {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    legendOffset: 46,
    legendPosition: 'middle',
    tickValues: 20,
    tickRotation: -22,
  },
})

export const dateParams = Object.freeze({
  xScale: { format: '%Y-%m-%d', type: 'time' },
  axisBottom: {
    format: '%d/%m/%y',
    legendOffset: 46,
    legendPosition: 'middle',
    tickValues: 15,
    tickRotation: -22,
  },
})

export const nivoLineProps = {
  ...nivoProps,
  colors: (d) => d.color,
  xScale: { type: 'linear', min: 'auto', max: 'auto' },
  yScale: {
    type: 'linear',
    min: 'auto',
    max: 'auto',
    stacked: false,
    reverse: false,
  },
  curve: 'monotoneX',
  layers: [
    'grid',
    'markers',
    'axes',
    'areas',
    'crosshair',
    'lines',
    'points',
    'slices',
    'mesh',
    'legends',
  ],
}

export function zip(arrays, limit) {
  if (!arrays.length) return []
  return (limit ? arrays[0].slice(0, limit) : arrays[0]).map(function (_, i) {
    return arrays.map(function (array) {
      return array[i]
    })
  })
}

export function interpolateXYLine(
  xscale,
  yscale,
  targetX,
  line,
  extrapolate = false,
) {
  const overIndex = line.findIndex(({ x, y }) => x >= targetX)
  if (overIndex === -1) {
    if (extrapolate && line.length > 1) {
      const p1 = line[0]
      const p2 = line[line.length - 1]
      const slope = (p2.y - p1.y) / (p2.x - p1.x)
      return { x: targetX, y: p1.y + slope * (targetX - p1.x) }
    }
    return null
  }
  if (overIndex !== 0) {
    const point = line[overIndex]
    if (point.x === targetX) return point
    const slope =
      (point.y - line[overIndex - 1].y) / (point.x - line[overIndex - 1].x)
    const y = line[overIndex - 1].y + slope * (targetX - line[overIndex - 1].x)
    return { x: targetX, y }
  }
  return line[overIndex]
}

export function getMMMNonFeatureColumns(model) {
  try {
    const target = model.training_config.target
    const features = model?.training_config?.extra_features ?? []
    return model.trainable_columns.filter(
      (c) =>
        c !== target &&
        !model.training_config.columns_to_ignore.includes(c) &&
        !features.includes(c),
    )
  } catch (e) {
    console.error(
      `Failed to procress non feature columns for model ${model?.id ?? '?'}`,
    )
    return []
  }
}

const errors = [
  'import_error_api',
  'train_error',
  'import_error_workers',
  'csv_error',
]

export const modelStatuses = [
  'importing',
  'created',
  'training',
  'trained',
  'train_error',
  'optimizing',
  'start_import',
  'edit_import',
  'import_error_workers',
  'csv_error',
  'connected_datasources',
  'classified_datasources',
  'import_error_api',
]

export function getModelStatus(model) {
  if (errors.includes(model?.status)) return 'error'
  if (model?.status === 'optimizing') return 'optimizing'
  if (model?.model_valid_train_data && model?.status !== 'training') {
    return 'trained'
  }
  return model?.status
}
